/**
 * @generated SignedSource<<3a468a59fff3358d54f813ca18be579d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MeetingMetricsNewInput = {
  filter?: string | null;
};
export type ManagerDashboardQuery$variables = {
  input: MeetingMetricsNewInput;
};
export type ManagerDashboardQuery$data = {
  readonly allMeetingMetricsNew: ReadonlyArray<{
    readonly email: string;
    readonly meetingId: string;
    readonly metrics: string;
    readonly orgId: string;
    readonly userId: string;
  }>;
};
export type ManagerDashboardQuery = {
  response: ManagerDashboardQuery$data;
  variables: ManagerDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MeetingMetrics",
    "kind": "LinkedField",
    "name": "allMeetingMetricsNew",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meetingId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orgId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metrics",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManagerDashboardQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManagerDashboardQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "81d7eb09964df138b9061b42106319f1",
    "id": null,
    "metadata": {},
    "name": "ManagerDashboardQuery",
    "operationKind": "query",
    "text": "query ManagerDashboardQuery(\n  $input: MeetingMetricsNewInput!\n) {\n  allMeetingMetricsNew(input: $input) {\n    meetingId\n    userId\n    orgId\n    metrics\n    email\n  }\n}\n"
  }
};
})();

(node as any).hash = "517794e005a84d422cf953ee2b003531";

export default node;
